<template>
    <div class="PlanStepThree-bj">
        <div class="PlanStepThree">
            <div class="PlanStepThreeHeader">
                创建单元
            </div>
            <div class="uniNameBox">
                <div class="unitName">
                    <span>单元名称</span>
                    <el-input :suffix-icon="form.unit_name ? 'el-icon-success' : ''" v-model="form.unit_name" style="width: 490px;margin-left: 20px;"></el-input>
                </div>
                <div class="unitPrice">
                    <span>通投出价（￥）</span>
                    <el-input @input="TongCast" placeholder="请输入正整数" :suffix-icon="form.offer ? 'el-icon-success' : ''" v-model="form.offer" style="width: 120px;margin-left: 20px"></el-input>
                </div>
            </div>
        </div>
        <div class="cut-off"></div>
        <div class="PlanStepThree2">
            <div class="PlanStepThree2Header">
                <span>资源位</span>
                <el-button class="headerBtn" style="margin-left: 20px;" @click="drawer = true">添加资源位</el-button>
                <el-button class="headerBtn" @click="deleResource">删除</el-button>
                <div class="PlanStepThree2Table" style="padding-left:74px ">
                    <el-table :data="tableData" @selection-change="handleSelectionChange" border style="width: 100%;flex: 1; margin-top: 20px" :cell-style="{height: '50px',color:'#343442', fontSize:'14px',fontFamily:'PingFang SC',fontWeight:400}" :header-cell-style="{fontWeight: '500', color: '#14141C', background: '#F5F5F5',height: '50px'}">
                        <el-table-column type="selection" width="55" align="center"></el-table-column>
                        <el-table-column label="资源位" prop="resourceState" width="300">
                            <template slot-scope="scope">
                                <div class="resourceState">
                                    <span style="font-size: 14px">{{scope.row.name}}</span>
                                    <img :src="scope.row.src" alt="">
                                </div>
                            </template>
                        </el-table-column>
<!--                        <el-table-column label="资源位提示信息" prop="hint"></el-table-column>-->
                        <!-- <el-table-column label="潜在买家数量" prop="customers"></el-table-column> -->
                        <el-table-column label="建议出价">20</el-table-column>
                        <el-table-column label="最低出价">
                            <template slot-scope="scope">
                                {{minOffer}}
                            </template>
                        </el-table-column>
                        <el-table-column label="平均出价">
                            <template slot-scope="scope">
                                {{averageOffer}}
                            </template>
                        </el-table-column>
                        <el-table-column label="竞争热度" prop="heat">
                            <template slot-scope="scope">
                                <el-rate
                                        v-model="scope.row.hot"
                                        disabled>
                                </el-rate>
                            </template>
                        </el-table-column>
                        <el-table-column label="转化率（%）" prop="conversion"></el-table-column>
                        <el-table-column label="点击率（%）" prop="click"></el-table-column>
                    </el-table>
                </div>
            </div>
        </div>
        <div class="cut-off"></div>
        <div class="PlanStepThree3">
            <div class="PlanStepThree3Header">
                <span>定向设置</span>
                <span>整体曝光人数预估</span>
                <el-popover
                        placement="top-start"
                        width="200"
                        trigger="click"
                        content="预估启用人群第二天可能带来的最大去重曝光访客数，预估值仅供参考，实际数据还会受到出价、投放时段等因素的影响。特殊标明的人群、默认人群和智能定向人群不参与此处的总体评估。">
                    <i slot="reference" class="iconfont" style="color: #D7D7D7;cursor: pointer">&#xe72c;</i>
                </el-popover>
                <span>{{exposurePerson}}</span>
                <span style="margin-left: 20px">整体曝光量预估</span>
                <el-popover
                        placement="top-start"
                        width="200"
                        trigger="click"
                        content="预估启用人群第二天可能带来的最大去重曝光量，预估值仅供参考，实际数据还会受到出价、投放时段等因素的影响。特殊标明的人群、默认人群和智能定向人群不参与此处的总体评估。">
                    <i slot="reference" class="iconfont" style="color: #D7D7D7;cursor: pointer">&#xe72c;</i>
                </el-popover>
                <span>{{personQuantity}}</span>
            </div>
            <div class="PlanStepThree3Table">
                <div class="PlanStepThree3TableHeader">
                    <div class="Header1">
                        <span style="font-size: 18px">投放地域</span>
                        <el-popover
                                style="margin:0 10px"
                                placement="top-start"
                                width="200"
                                trigger="click"
                                content="地域设置可以帮助您实现特定区域定向，不同的地域设置会影响最终的【整体曝光人数预估】和【整体曝光量预估】。">
                            <i slot="reference" class="iconfont" style="color: #D7D7D7;cursor: pointer">&#xe72c;</i>
                        </el-popover>
                        <el-radio-group v-model="radio2"  @change="selectTerritory">
                            <el-radio :label="1">不限</el-radio>
                            <el-radio @click.native="drawer2 = true" :label="2">特定区域</el-radio>
                        </el-radio-group>
                        <span style="margin-left: 20px">已选择<b style="font-weight: 500;color: #FD4446">{{selectRegion.length}}</b>个区域</span>
                    </div>
                    <div class="Header2">
                        <span style="font-size: 18px">人群定向</span>
                        <el-popover
                                style="margin:0 10px"
                                placement="top-start"
                                width="200"
                                trigger="click"
                                content="广告可按照特定的人群的覆盖范围投放并设置溢价。">
                            <i slot="reference" class="iconfont" style="color: #D7D7D7;cursor: pointer">&#xe72c;</i>
                        </el-popover>
                        <span style="font-size: 18px">已选人群<b style="font-weight: 500;color: #FD4446">{{selctCrowd}}</b>/6</span>
                    </div>
                </div>
                <div class="tableBox">
                    <div class="table-header">定向人群</div>
                    <el-table :data="tableData3" border style="width: 100%;flex: 1;" :cell-style="{height: '50px',color:'#343442', fontSize:'14px',fontFamily:'PingFang SC',fontWeight:400}" :header-cell-style="{fontWeight: '500', color: '#14141C', background: '#F5F5F5',height: '50px'}">
                        <el-table-column label="溢价人群" prop="premiumCrowd">
                            <template slot-scope="scope">
                                <span>{{scope.row.premiumCrowd}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="是否启动人群">
                            <template slot-scope="scope">
                                <el-switch
                                        @change="selectSwitch(scope.row,scope.$index)"
                                        v-model="scope.row.switchValue"
                                        active-color="#20D08C"
                                        inactive-color="#DFDFDF">
                                </el-switch>
                            </template>
                        </el-table-column>
                        <el-table-column label="溢价系数" prop="premium">
                            <template slot-scope="scope">
                                <el-input @change="watchMax(scope.row,scope.$index)" oninput="value=value.replace(/[^\d]/g,'')" :disabled="!scope.row.switchValue" v-model="scope.row.premium" style="width: 90px;"></el-input><span style="margin-left: 4px">%</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="转化率（%）" prop="conversion">
                            <template slot-scope="scope">
                                {{scope.row.conversion!==null ? scope.row.conversion : '-'}}
                            </template>
                        </el-table-column>
                        <el-table-column label="质量指数" prop="click">
                            <template slot-scope="scope">
                                {{scope.row.click!==null ? scope.row.click : '-'}}
                            </template>
                        </el-table-column>
                        <el-table-column label="预估人数" prop="customers_click">
                            <template slot-scope="scope">
                                {{scope.row.customers_click!==null ? scope.row.customers_click : '-'}}
                            </template>
                        </el-table-column>
                        <el-table-column label="潜在买家指数" prop="customers">
                            <template slot-scope="scope">
                                {{scope.row.customers!==null ? scope.row.customers : '-'}}
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
        </div>
        <div class="cut-off-btn">
            <el-button class="offBtn" @click="toBack">取消</el-button>
            <el-button class="offBtn" @click="toNext">确定</el-button>
        </div>
        <el-drawer
                @open="openResource"
                size="70%"
                :show-close="false"
                :visible.sync="drawer">
            <i style="position: absolute;right: 30px;top: 30px;font-size: 30px;cursor: pointer" @click="drawer = false" class="el-icon-close"></i>
            <div class="drawerBox">
                <div class="drawerBoxHeader">
                    <p>添加资源位<span style="color:red;font-size: 14px;">（钻展出价分析）</span></p>
                    <span class="span1">目前展位广告下的全部资源位均位于"商城站内"，建议结合营销目标和预算进行选择</span>
                    <span class="span2">已选择<b>{{tableAdd.length}}</b>个资源位</span>
                </div>
                <div class="drawerBoxTable">
                    <el-table :data="tableData2" @selection-change="handleSelectionChange2" border
                              ref="multipleTable"
                              :row-key="getRowKeys"
                              style="width: 100%;flex: 1; margin-top: 20px"
                              :cell-style="{height: '60px',color:'#343442', fontSize:'14px',fontFamily:'PingFang SC',fontWeight:400}"
                              :header-cell-style="{fontWeight: '500', color: '#14141C', background: '#F5F5F5',height: '60px'}">
                        <el-table-column type="selection" :reserve-selection="true" width="55" align="center"></el-table-column>
                        <el-table-column label="资源位" prop="resourceState" width="300">
                            <template slot-scope="scope">
                                <div class="resourceState">
                                    <span class="text-overflow-2">{{scope.row.name}}</span>
                                    <img :src="scope.row.src" alt="">
                                </div>
                            </template>
                        </el-table-column>
<!--                        <el-table-column label="资源位提示信息" prop="hint"width="150"></el-table-column>-->
                        <!-- <el-table-column label="潜在卖家数量" prop="customers" width="200"></el-table-column> -->
                        <el-table-column label="建议出价" width="200">20</el-table-column>
                        <el-table-column label="最低出价" prop="minOffer">
                            <template slot-scope="scope">
                                <span>{{minOffer}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="平均出价" prop="averageOffer">
                            <template slot-scope="scope">
                                <span>{{averageOffer}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="竞争热度" prop="heat" width="150">
                            <template slot-scope="scope">
                                <el-rate
                                        v-model="scope.row.hot"
                                        disabled>
                                </el-rate>
                            </template>
                        </el-table-column>
                        <el-table-column label="点击率（%）" prop="click" width="150"></el-table-column>
                        <el-table-column label="转化率（%）" prop="conversion" width="150"></el-table-column>
                    </el-table>
                    <div class="drawerConfirm">
                        <span></span>
                        <el-button class="drawerConfirmBtn" @click="confirmAdd">确定</el-button>
                    </div>
                </div>
            </div>
        </el-drawer>
        <el-drawer
                size="60%"
                :show-close="false"
                :visible.sync="drawer2">
            <div class="territory">
                <div class="territory-title"><span>地域设置</span>
                    <i style="font-size: 30px;cursor: pointer" @click="drawer2 = false" class="el-icon-close"></i></div>
                <el-checkbox @change="selectAll" style="margin-top: 20px" v-model="checkedAll">全选</el-checkbox>
                <div class="territory-select">
                    <el-cascader-panel
                            v-model="panelList"
                            ref="multiplePanel"
                            @change="selectPositioning"
                            :options="territortList"
                            :props="props"
                            collapse-tags
                            clearable>
                    </el-cascader-panel>
                </div>
                <div class="territory-btn">
                    <span></span>
                    <el-button class="btns" @click="drawer2 = false">确定</el-button>
                </div>
            </div>
        </el-drawer>
    </div>
</template>

<script>
    import _ from "underscore";
    import { mapActions } from 'vuex';
    export default {
        name: "PlanStepThree",
        data(){
            return {
                editResData:null,
                checkedAll:false,
                props: { multiple: true, value: 'code', label: 'name', children:'province'},
                territortList:[],
                form:{
                    unit_name:'',
                    offer:''
                },
                panelList:[],
                radio:1,
                radio2:1,
                drawer:false,
                drawer2:false,
                averageOffer:null, // 平均出价
                minOffer:null, //最低出价
                tableData:[],
                tableData2:[],
                tableData3:[
                    {
                        type:1,
                        premiumCrowd:'核心人群',
                        premium:100,
                        click:null,
                        conversion:null,
                        customers_click:null,
                        customers:null,
                        switchValue:false
                    },
                    {
                        type:2,
                        premiumCrowd:'产品相关',
                        premium:100,
                        click:null,
                        conversion:null,
                        customers_click:null,
                        customers:null,
                        switchValue:false
                    },
                    {
                        type:3,
                        premiumCrowd:'兴趣意图',
                        premium:100,
                        click:null,
                        conversion:null,
                        customers_click:null,
                        customers:null,
                        switchValue:false
                    },
                    {
                        type:4,
                        premiumCrowd:'店铺相关',
                        premium:100,
                        click:null,
                        conversion:null,
                        customers_click:null,
                        customers:null,
                        switchValue:false
                    },
                    {
                        type:5,
                        premiumCrowd:'店铺人群',
                        premium:100,
                        click:null,
                        conversion:null,
                        customers_click:null,
                        customers:null,
                        switchValue:false
                    },
                    {
                        type:6,
                        premiumCrowd:'类目相关人群',
                        premium:100,
                        click:null,
                        conversion:null,
                        customers_click:null,
                        customers:null,
                        switchValue:false
                    },
                ],
                tableAdd:[],
                tableDele:[],
                selectRegion:[],
                onePlanTwo:null,
                selctCrowd:null,
                exposurePerson:0,
                personQuantity:0,
                //菜单
            }
        },
        activated(){
          // console.log('aaa',this.tableData3)
            this.tableData3.map((item,index)=>{
                if(!item.premiumCrowd){
                    if(item.type === 1){
                        this.$set(item,'premiumCrowd','核心人群');
                        this.$set(item,'switchValue',true)
                    } else if(item.type === 2){
                        this.$set(item,'premiumCrowd','认知人群');
                        this.$set(item,'switchValue',true)
                    } else if(item.type === 3){
                        this.$set(item,'premiumCrowd','意向人群');
                        this.$set(item,'switchValue',true)
                    } else if(item.type === 4){
                        this.$set(item,'premiumCrowd','竞品人群');
                        this.$set(item,'switchValue',true)
                    } else if(item.type === 5){
                        this.$set(item,'premiumCrowd','店铺人群');
                        this.$set(item,'switchValue',true)
                    } else if(item.type === 6){
                        this.$set(item,'premiumCrowd','类目相关人群');
                        this.$set(item,'switchValue',true)
                    }
                }
            })
            this.selctCrowdList()
        },
        mounted() {
            this.selectTerritory();
            this.getEditData();
            if (!this.$route.query.editId) {
              this.getSourcesList();
            }
        },
        created() {
            this.getPosition();
            this.getOnePlanTwo();
            this.selctCrowdList();
            // console.log(245,this.$route)
            // if(this.$route.query.succeedIndex){
            //     location.reload()
            // }
            // this.getCrowd();
        },
        computed: {
            menus() {
                return this.$store.getters.dataMenuList;
            }
        },
        methods: {
          getRowKeys(row) {
            return row.type
          },
            ...mapActions([
                'setDataMenusList'
            ]),
            // 更新状态
            operation_updateStatus(index1, index2,  status) {
                let param = {
                    offset1: Number(index1),
                    offset2: Number(index2),
                    status: Number(status),
                }
                this.$http.axiosGetBy(this.$api.data_setUserOpList, param, (res) => {
                    if (res.code === 200) {
                        this.setDataMenusList();
                    } else {
                        this.$message.warning(res.msg);
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            getEditData(){
              if(this.$route.query.editId){
                  this.panelList = []
                  this.tableData = []
                  this.$http.axiosGetBy(this.$api.storeDrainage, {id:this.$route.query.editId}, res=>{
                      // console.log(412,res)
                      if(res.code === 200){
                          this.editResData = res.data;
                          let resData = res.data;
                          this.form.unit_name = resData.unit_name;
                          this.form.offer = JSON.parse(resData.offer);
                          this.getSourcesList();
                          // this.tableData3 = resData.crowd
                          if(resData.crowd.length){
                              for(let i = 0; i < resData.crowd.length; i++){
                                  for(let j = 0; j < this.tableData3.length; j++){
                                      if(resData.crowd[i].type === this.tableData3[j].type){
                                          if(resData.crowd[i].switchValue === true){
                                              this.tableData3[j].click = resData.crowd[i].click;
                                              this.tableData3[j].conversion = resData.crowd[i].conversion;
                                              this.tableData3[j].customers = resData.crowd[i].customers;
                                              this.tableData3[j].customers_click = resData.crowd[i].customers_click;
                                              this.tableData3[j].premium = resData.crowd[i].premium;
                                              this.tableData3[j].switchValue = resData.crowd[i].switchValue;
                                          }
                                      }
                                  }
                              }
                          }
                          // for(let i = 0; i < this.tableData3.length; i++){
                          //     for(let j = 0; j < resData.crowd.length; j++){
                          //         if(this.tableData3[i].type === resData.crowd[j].type){
                          //             this.tableData3[i].switchValue = true;
                          //             this.tableData3[i].click = resData.crowd[j].click;
                          //             this.tableData3[i].conversion = resData.crowd[j].conversion;
                          //             this.tableData3[i].customers = resData.crowd[j].customers;
                          //             this.tableData3[i].customers_click = resData.crowd[j].customers_click;
                          //             this.tableData3[i].premium = resData.crowd[j].premium;
                          //         }
                          //     }
                          // }
                          this.selctCrowdList()
                      } else {
                          this.$message.warning(res.msg)
                      }
                  })
              }
            },
            selectAll(val){
                // console.log(398,this.territortList)
                this.panelList = []
                this.selectRegion = []
                if(val){
                    for(let i = 0; i < this.territortList.length; i++){
                        for(let j = 0; j < this.territortList[i].province.length; j++){
                            let list = [];
                            list.push(this.territortList[i].code);
                            list.push(this.territortList[i].province[j].code);
                            this.panelList.push(list)
                        }
                    }
                    this.panelList.map(item=>{
                        this.selectRegion.push(item[item.length-1])
                    })
                } else {
                    this.panelList = [];
                    this.selectRegion = []
                }
            },
            // 地区选择
            selectPositioning(val){
                // console.log(442,val)
                this.selectRegion = [];
                if(val.length){
                    val.map(item=>{
                        this.selectRegion.push(item[item.length-1])
                    })
                }
            },
            selctCrowdList(){
                let list = [];
                this.tableData3.map(item=>{
                    if(item.switchValue){
                        list.push(item)
                    }
                });
                this.selctCrowd = list.length;
                this.exposurePerson=0;
                this.personQuantity=0;
                this.tableData3.map(item=>{
                    if(item.switchValue){
                        this.exposurePerson += item.customers_click;
                        this.personQuantity += item.customers;
                    }
                })
            },
            TongCast(){
                if(!Number(this.form.offer)){
                    this.form.offer=''
                } else {
                    this.form.offer = parseInt(this.form.offer);
                }
                this.tableData3.map(item=>{
                    item.switchValue = false;
                    item.click = null;
                    item.conversion = null;
                    item.customers = null;
                    item.customers_click = null;
                    item.premium = 100;
                })
                this.selctCrowd = 0;
                this.exposurePerson=0;
                this.personQuantity=0;
            },
            selectSwitch(row,index){
                if(!this.form.offer){
                    this.$message.warning('请填写通投出价!');
                    this.tableData3[index].switchValue = false;
                    return
                }
                if(row.switchValue){
                    let timeInterval = JSON.stringify(this.onePlanTwo.time_interval);
                    let param = {
                        type:row.type,
                        offer:this.form.offer * (row.premium/100),
                        budget:this.onePlanTwo.budget,
                        delivery_speed:this.onePlanTwo.delivery_speed,
                        time_interval:timeInterval
                    }
                    this.$http.axiosGetBy(this.$api.crowd, param, res=>{
                        if(res.code === 200){
                            this.tableData3[index].click = res.data.click;
                            this.tableData3[index].conversion = res.data.conversion;
                            this.tableData3[index].customers = res.data.customers;
                            this.tableData3[index].customers_click = res.data.customers_click;
                            this.selctCrowdList()
                        } else {
                            this.$message.warning(res.msg);
                            this.tableData3[index].switchValue = false;
                        }
                    })
                } else {
                    this.tableData3[index].click = null;
                    this.tableData3[index].conversion = null;
                    this.tableData3[index].customers = null;
                    this.tableData3[index].customers_click = null;
                    this.selctCrowdList()
                }
            },
            watchMax(row,index){
                if(!Number(this.tableData3[index].premium)){
                    this.tableData3[index].premium = 100;
                } else {
                    this.tableData3[index].premium = parseInt(this.tableData3[index].premium)
                }
                if(row.premium < 30 || row.premium > 500){
                    this.$message.warning('溢价系数再30~500之间！');
                    this.tableData3[index].premium = 100;
                    this.selectSwitch(row,index)
                } else {
                    this.selectSwitch(row,index)
                }
            },
            getOnePlanTwo(){
                if(this.$lockr.get('onePlanTwo')){
                    this.onePlanTwo = this.$lockr.get('onePlanTwo')
                }
            },
            // 获取地区
            getPosition(){
                this.$http.axiosGet(this.$api.position, res=>{
                    if(res.code === 200){
                        if(this.$route.query.editId){
                            this.$http.axiosGetBy(this.$api.storeDrainage, {id:this.$route.query.editId}, resRegion=>{
                                if(resRegion.code === 200){
                                    this.territortList = res.data;
                                    let resData = resRegion.data;
                                    this.selectRegion = resData.region;
                                    // console.log(this.territortList)
                                    let list4 =[]
                                    for(let i = 0; i < this.territortList.length; i++){
                                        for(let j =0; j < this.territortList[i].province.length; j++){
                                            list4.push(this.territortList[i].province[j].code)
                                        }
                                    }
                                    if(this.selectRegion.length < list4.length){
                                        this.radio2 = 2
                                    } else {
                                        this.radio2 = 1
                                    }
                                    // panelList
                                    let list =[]
                                    for(let i = 0; i < this.territortList.length; i++){
                                        for(let j =0; j < this.territortList[i].province.length; j++){
                                            for(let l = 0; l < resData.region.length; l++){
                                                if(this.territortList[i].province[j].code == resData.region[l]){
                                                    let list2 = [];
                                                    list2.push(this.territortList[i].code);
                                                    list2.push(this.territortList[i].province[j].code);
                                                    list.push(list2)
                                                }
                                            }
                                        }
                                    }
                                    this.panelList = list
                                } else {
                                    this.$message.warning(resRegion.msg)
                                }
                            })
                        } else {
                            this.territortList = res.data;
                            if(this.radio2 === 2){
                                this.drawer2 = true;
                            } else if(this.radio2 === 1){
                                this.selectRegion = [];
                                for(let i = 0; i < this.territortList.length; i++){
                                    if(this.territortList[i].province){
                                        for(let j = 0; j < this.territortList[i].province.length; j++){
                                            this.selectRegion.push(this.territortList[i].province[j].code)
                                        }
                                    }
                                }
                            }
                        }
                    } else {
                        this.$message.warning(res.msg)
                    }
                })
            },

            // 获取可添加的资源位列表
            getSourcesList(){
                this.$http.axiosGet(this.$api.resources, res=>{
                    if(res.code === 200){
                        this.tableData2 = res.data.data;
                        this.averageOffer = res.data.averageOffer;
                        this.minOffer = res.data.minOffer;
                        this.tableData2.map(item=>{
                            if(item.type===1 || item.type === 2){
                                item.src = require('./../../../assets/images/dibutonglan.png')
                            } else if(item.type === 3){
                                item.src = require('./../../../assets/images/Mydibutonglan.png')
                            } else if(item.type === 4){
                                item.src = require('./../../../assets/images/PcRight.png')
                            } else if(item.type === 5){
                                item.src = require('./../../../assets/images/PcIndex.png')
                            } else if(item.type === 6){
                                item.src = require('./../../../assets/images/PcRight.png')
                            } else if(item.type === 7){
                                item.src = require('./../../../assets/images/PcIndex.png')
                            }
                        })
                        // this.getEditData()
                        if(this.$route.query.editId){
                            let resData = this.editResData;
                            let list2 =[]
                            for(let i = 0; i < this.tableData2.length; i++){
                                for(let j = 0; j < resData.resources.length; j++){
                                    if(resData.resources[j] === this.tableData2[i].type){
                                        list2.push(this.tableData2[i])
                                    }
                                }
                            }
                            this.tableData = list2
                        }
                    } else {
                        this.$message.warning(res.msg)
                    }
                })
            },
          openResource() {
            this.$nextTick(() => {
              this.$refs.multipleTable.clearSelection();
              this.tableData.map(row => {
                for (let i = 0; i < this.tableData2.length; i++) {
                  if (row.type === this.tableData2[i].type) {
                    this.$refs.multipleTable.toggleRowSelection(this.tableData2[i], true)
                  }
                }
              })
            })
          },
            deleResource(){
                if(!this.tableDele.length){
                    this.$message.warning('请选择要删除的资源位！');
                    return
                }
                this.$confirm(`您确定要删除选中的${this.tableDele.length}个资源吗？`,'操作确认',{
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(()=>{
                    // for(let i = 0; i < this.tableData.length; i++){
                    //     for(let j = 0; j < this.tableDele.length; j++){
                    //         if(this.tableData[i].type === this.tableDele[j].type){
                    //             this.tableData.splice(i,1)
                    //         }
                    //     }
                    // }
                  let delArr = this.tableDele.map(item => item.type)
                  this.tableData = this.tableData.filter(item => !delArr.includes(item.type))
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                }).catch(()=>{
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                })
            },
            handleSelectionChange(val){
                // console.log(96,val)
                this.tableDele = val;
            },
            handleSelectionChange2(val){
                this.tableAdd = val;
            },
            selectTerritory(val){
                // console.log(314,val)
                // console.log(this.radio2)
                if(this.radio2 === 2){
                    this.drawer2 = true;
                } else if(this.radio2 === 1){
                    this.selectRegion = [];
                    for(let i = 0; i < this.territortList.length; i++){
                        if(this.territortList[i].province){
                            for(let j = 0; j < this.territortList[i].province.length; j++){
                                this.selectRegion.push(this.territortList[i].province[j].code)
                            }
                        }
                    }
                    // console.log(470,this.selectRegion)
                }
            },
            confirmAdd(){
                if(this.tableAdd.length){
                    // _.each(this.tableAdd, (item) => {
                    //     let tmp = _.find(this.tableData, { type: item.type});
                    //     if (!tmp) {
                    //         this.tableData.push(item);
                    //     }
                    // });
                    this.tableData = this.tableAdd
                    this.drawer = false;

                    /*this.tableAdd.map(item=>{
                        if(this.tableData.includes(item)){
                            this.drawer = false
                        } else {
                            this.tableData.push(item)
                            this.drawer = false
                        }
                    })*/
                } else {
                    this.$message('请选择要添加的资源位！')
                }
            },
            toBack(){
                this.$router.push({
                    path:'/student/operationpromotion/promotionmanagement'
                })
            },
            toNext(){
                if(this.$lockr.get('onePlanTwo')) {
                    this.onePlanTwo = this.$lockr.get('onePlanTwo')
                }
                if(!this.form.unit_name || !this.form.offer){
                    this.$message.warning('单元名称或通投出价不能为空！');
                    return;
                }
                if(this.tableData.length < 1){
                    this.$message.warning('资源位最少添加一个！');
                    return;
                }
                if(this.selectRegion.length < 1){
                    this.$message.warning('地域最少设置一个！');
                    return;
                }
                let resourcesList = [];
                this.tableData.map(item=>{
                    resourcesList.push(item.type)
                })
                let resources = JSON.stringify(resourcesList);
                // let crowdList = this.tableData3;
                // let crowdList2 = [];
                // this.tableData3.map(item=>{
                //     crowdList2.push(item)
                // })
                // crowdList2.map(item=>{
                //     delete item.switchValue;
                //     delete item.premiumCrowd
                // })
                // crowdList.map(item=>{
                //     if(item.switchValue){
                //         delete item.switchValue;
                //         delete item.premiumCrowd;
                //         crowdList2.push(item)
                //     }
                // })
                let crowdList3 = JSON.stringify(this.tableData3);
                let region = JSON.stringify(this.selectRegion);
                let time_interval = JSON.stringify(this.onePlanTwo.time_interval);
                let formData = new FormData();
                formData.append('name',this.onePlanTwo.name);
                formData.append('billing_method',this.onePlanTwo.billing_method);
                formData.append('budget',this.onePlanTwo.budget);
                formData.append('time_interval',time_interval);
                formData.append('delivery_speed',this.onePlanTwo.delivery_speed);
                formData.append('unit_name',this.form.unit_name);
                formData.append('offer',this.form.offer);
                formData.append('resources',resources);
                formData.append('region',region);
                formData.append('crowd',crowdList3);
                formData.append('group_name',this.onePlanTwo.group_name);
                formData.append('period',localStorage.getItem('cycleNumber'));
                if(this.$route.query.editId){
                    formData.append('id',this.$route.query.editId)
                }
                this.$http.axiosPost(this.$api.storeDrainage, formData, res=>{
                    if(res.code === 200){
                        if (Number(this.$lockr.get('competition_type')) === 1) {
                            let menus = this.menus;
                            if (Number(menus[1].children[2].status) !== 2) {
                                this.operation_updateStatus(1,2,2);
                            }
                        }
                        if(this.$route.query.editId){
                            this.$router.push({
                                path:'/student/operationpromotion/boothadvertisingsucceed',
                                query:{
                                    editId:this.$route.query.editId
                                }
                            })
                        } else {
                            this.$router.push({
                                path:'/student/operationpromotion/boothadvertisingsucceed',
                            })
                        }
                    } else {
                        // console.log(793,this.tableData3)
                        this.$message.warning(res.msg)
                    }
                })
            }
        },
    }
</script>

<style scoped lang="scss">
    /deep/.el-button:focus, .el-button:hover {
        border-color: #FD4446;
        color: #FD4446;
        background: #ffffff;
    }
    .territory{
        /deep/.el-checkbox__label{
            color: #333333;
        }
    }
    /deep/.el-cascader-menu__list{
        min-width: 500px!important;
    }
    /deep/.el-cascader-menu .el-cascader-menu__wrap{
        height: 365px;
    }
    /deep/.el-cascader-node.in-active-path, .el-cascader-node.is-active, .el-cascader-node.is-selectable.in-checked-path{
        color: #333333!important;
    }
    /deep/.el-cascader-menu__list .is-active .el-cascader-node__label{
        color: #333333;
    }
    /deep/.el-button--default:hover{
        border-color: #FD4446;
        color: #FD4446;
        background: #ffffff;
    }
    /deep/.el-checkbox .is-checked .el-checkbox__inner{
        background-color: #FD4446;
        border-color: #FD4446;
    }
    /deep/.is-indeterminate .el-checkbox__inner{
        background-color: #FD4446;
        border-color: #FD4446;
    }

    /*/deep/ .el-checkbox__input.is-indeterminate .el-checkbox__inner{*/
    /*    background-color: #FD4446;*/
    /*    border-color: #FD4446;*/
    /*}*/
    /deep/.el-radio-group .is-active .el-radio-button__inner{
        background: #F5F5F5!important;
    }
    /deep/.el-radio-group .el-radio-button--medium .el-radio-button__inner{
        border-color: #DCDFE6;
        color: #333333;
        box-shadow:none
    }
    /deep/.is-checked .el-radio__inner{
        border-color: #FD4446!important;
        background: #FD4446!important;
    }
    /deep/.el-radio__inner{
        width: 20px;
        height: 20px;
    }
    /deep/.is-checked .el-radio__label{
        color: #333333!important;
        font-size: 18px;
    }
    /deep/.el-radio .el-radio__label{
        color: #1E63F1;
        font-size: 18px;
    }
    /deep/.el-icon-success{
        color: #20D08C;
    }
    /deep/.el-checkbox__inner:hover{
        border-color: #FD4446!important;
    }
    .resourceState{
        display: flex;
        justify-content: space-between;
        align-items: center;
        img{
            width: 50px;
            height: 50px;
            margin-left: 10px;
        }
    }
    .PlanStepThree-bj{
        /*height: calc(100vh - 200px);*/
        .drawerBox{
            padding:0 60px;
            overflow-y: auto;
            height: calc(100vh - 100px);
            .drawerBoxHeader{
                display: flex;
                flex-direction: column;
                p{
                    color: #333333;
                    font-size: 24px;
                    line-height: 1;
                }
                .span1{
                    margin-top: 20px;
                    color: #333333;
                    font-size: 16px;
                    display: inline-block;
                    height: 40px;
                    background: #EBF3FF;
                    line-height: 40px;
                    padding-left: 30px;
                    border: 1px solid #D7E8FF;
                }
                .span2{
                    margin-top: 10px;
                    color: #333333;
                    font-size: 16px;
                    b{
                        color: #FD4446;
                        font-weight: 400;
                    }
                }
                .selectRadio{
                    margin-top: 20px;
                }
            }
            .drawerBoxTable{
                .drawerConfirm{
                    display: flex;
                    justify-content: space-between;
                    padding-top: 40px;
                    .drawerConfirmBtn{
                        width: 120px;
                        background: #FD4446;
                        border-radius: 6px;
                        border-color: #FD4446;
                        color: #ffffff;
                    }
                }
            }

        }
    }
    .PlanStepThree{
        padding: 20px 30px;
        .PlanStepThreeHeader{
            color: #333333;
            font-size: 28px;
        }
        .uniNameBox{
            /*padding-bottom: 30px;*/
            span{
                color: #333333;
                font-size: 18px;
                font-weight: 500;
            }
            .unitName{
                display: flex;
                align-items: center;
                padding-left: 56px;
                padding-top: 70px;
            }
            .unitPrice{
                display: flex;
                align-items: center;
                padding-left: 132px;
                padding-top: 50px;
            }
        }
    }
    .PlanStepThree2{
        padding: 20px 30px;
        .PlanStepThree2Header{
            padding-left: 74px;
            span:nth-child(1){
                font-size: 18px;
            }
            .headerBtn{
                height: 36px;
                line-height: 0;
            }
        }
    }
    .PlanStepThree3{
        padding: 20px 30px;
        .PlanStepThree3Header{
            padding-left: 56px;
            span:nth-child(1){
                font-width: 500;
            }
            span:nth-child(2){
                margin-left: 20px;
            }
            span{
                font-size: 18px;
                color: #333;
                font-weight: 400;
            }
            i{
                margin: 0 10px;
            }
        }
        .PlanStepThree3Table{
            padding: 20px;
            border: 1px solid rgba(151, 151, 151,0.3);
            margin-left: 150px;
            margin-top: 16px;
            .PlanStepThree3TableHeader{
                .Header1{
                    display: flex;
                    align-items: center;
                }
                .Header2{
                    margin-top: 14px;
                }
            }
            .tableBox{
                margin-left: 110px;
                margin-top: 20px;
                .table-header{
                    height: 50px;
                    background: #F0F0F0;
                    color: #333333;
                    font-size: 18px;
                    line-height: 50px;
                    padding-left: 20px;
                }
            }
        }
    }
    .cut-off{
        width: 100%;
        height: 20px;
        background: #F7F9FB;
    }
    .cut-off-btn{
        padding: 30px;
        width: 100%;
        background: #F7F9FB;
        .offBtn{
            width: 140px;
            height: 50px;
            font-size: 20px;
        }
        .offBtn:nth-child(2){
            background: #FD4446;
            border-color: #FD4446;
            color: #ffffff;
        }
    }
    .territory{
        overflow-y: auto;
        height: calc(100vh - 100px);
        padding: 0 60px;
        .territory-title{
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: #333333;
            font-size: 24px;
            font-weight: 500;
        }
        .territory-select{
            margin-top: 20px;
        }
        .territory-btn{
            padding-top: 100px;
            display: flex;
            justify-content: space-between;
            .btns{
                width: 120px;
                height: 50px;
                background-color: #FD4446;
                border-color: #FD4446;
                color: #ffffff;
                font-size: 20px;
            }
        }
    }
</style>
